@tailwind base;
@tailwind components;
@tailwind utilities;

:where(html, body, #root) {
   height: 100%;
}

.hide-scroll {
   scrollbar-width: none;
   -ms-overflow-style: none;
}

.hide-scroll::-webkit-scrollbar {
    display: none;
}

.layout-bg {
    background: linear-gradient(0deg, #fff 69%, #131212 69%, #131212 100%);
}

.active-link-border {
    clip-path: polygon(38% 71%, 54% 71%, 100% 71%, 80% 75%, 56% 77%, 41% 77%, 20% 75%, 0 71%);
    background: transparent radial-gradient(closest-side at 50% 50%, #59C5C6 0%, #174E9F 100%) 0% 0% no-repeat padding-box;
}

.triangle {
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.bg-gold-gradient {
    background: transparent radial-gradient(closest-side at 9% 50%, #C2AB7E 0%, #61563F 100%) 0% 0% no-repeat padding-box;
}

.call-info-clip-path {
    clip-path: polygon(9% 18%, 12% 29%, 15% 42%, 15% 57%, 13% 71%, 7% 86%, 0 100%, 0 0);
}

.scroll-bar-left {
    direction:rtl;
}

.scroll-bar-left div{
    direction:ltr;
}

.Toastify__toast-container {
    max-width: 450px;
    width: 100%;
}

    /* DONT TOUCH THIS IS FOR REACT DATEPICKER */

.react-datepicker-wrapper {
    width: max-content;
    margin-left: auto;
}

.react-datepicker__header {
    background-color: #fff;
}
.react-datepicker__day--in-range {
    background-color: #0c75ff;
}

@media (max-width: 640px) {
    .react-datepicker-wrapper {
        margin-left: 0;
        margin-top: 0.75rem;
    }
}

/* DONT TOUCH THIS IS FOR REACT QUILL EDITOR */

.ql-toolbar {
    border-radius: 10px 10px 0 0;
}

.ql-container {
    border-radius: 0 0 10px 10px;
    height: 150px;
}

:where(#emailTemplate) h1 {
    font-size: 2rem;
}

:where(#emailTemplate) h2 {
    font-size: 1.5rem;

}:where(#emailTemplate) h3 {
    font-size: 1.25rem;
}

 .recharts-legend-item-text {
     color: #000 !important;
     font-size: 12px;
 }

@media (min-width: 767px) {
    .ql-container {
        height: 250px;
    }
}






